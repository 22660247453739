import React, { Fragment, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';

const MainLayout = ({ children, langController, lang }) => {
    const [langVar, setLangVar] = useState(true);
    const urlHome = 'https://transroute.com.mx'

    const handleLangChange = () => {
        let newLangController = !langVar;
        if(newLangController){
            langController('eng');
        }else{
            langController('esp');
        }
        setLangVar(!langVar);
    }

    return (
        <Fragment>
            <header className="container-fluid topbar">
                <Container>
                    <Row>
                        <Col xs={12} md={6} lg={6} className="column-logo">
                            <a className="navbar-brand" target={'_blank'} href={urlHome}>
                                <img src={process.env.PUBLIC_URL + '/logo.png'} className="d-inline-block align-top" alt="Transroute" loading="lazy" />
                            </a>
                            <div>
                                <img src={process.env.PUBLIC_URL + '/la-isla-tour-logo.png'} alt="La isla tour" />
                            </div>
                        </Col>
                        <Col xs={12} md={6} className="column-info">
                            <div className={'info-contact text-center text-lg-right'}>
                                {lang==='eng'?'Reservation Phone:':'Reservacion por Teléfono:'} <a href="tel:+526121431844">+52 624 143 18 44</a><br />
                                Email: <a href="mailto:recepcion@transroute.com.mx">recepcion@transroute.com.mx</a>
                            </div>
                            <div className="ml-4">
                                <div
                                    onClick={handleLangChange}>{langVar ? <img src={process.env.PUBLIC_URL + "/flag-mx.png"} loading="lazy" /> : <img src={process.env.PUBLIC_URL + "/flag-us.png"} loading="lazy" />}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </header>
            <div className="bar-color-top" />
            {children}
            <footer className={'container-fluid, mt-5'}>
                <Container>
                    <Row>
                        <div xs={12} lg={6}>
                            <p>BajaTravel Transportation © 2024 <br />Los Cabos, Baja California Sur, México</p>
                        </div>
                    </Row>
                </Container>
            </footer>
        </Fragment>
    );
}

export default MainLayout;