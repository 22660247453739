import React, { useState } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { fab } from '@fortawesome/free-brands-svg-icons'
// Layout
import MainLayout from './components/layout/MainLayout';
import Booking from './components/booking/Booking';
import Confirmation from './components/confirmation/Confirmation';

import {
  faPlaneArrival,
  faPlaneDeparture,
  faCalendarAlt,
  faUsers,
  faUser,
  faSuitcase,
  faRoute,
  faExclamationTriangle,
  faShuttleVan,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
  faPenAlt,
  faLock,
  faUserFriends,
  faFileInvoiceDollar,
  faSignOutAlt,
  faDownload,
  faEdit,
  faCreditCard,
  faMoneyBillWave,
  faCalendarCheck,
  faCheck

} from '@fortawesome/free-solid-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faCheck, faCalendarCheck, faMoneyBillWave,faCreditCard, fab, faSignOutAlt, faDownload, faFileInvoiceDollar, faPlaneArrival, faPlaneDeparture, faCalendarAlt, faUsers, faSuitcase, faRoute, faExclamationTriangle, faShuttleVan, faMapMarkedAlt, faMapMarkerAlt, faUser, faPhone, faEnvelope, faPenAlt, faLock, faUserFriends, faEdit);

function App() {
  const [languageController, setLanguageController] = useState('eng');

  return (
    <BrowserRouter>
        <MainLayout langController={setLanguageController} lang={languageController} >
            <Route path='/' exact component={() => <Booking lang={languageController}/>} />
            <Route path='/confirmation' exact component={Confirmation} />
        </MainLayout>
    </BrowserRouter>
  );
}

export default App;
