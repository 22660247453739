const configs = {
    // URL_API_DEV: "http://localhost:4002/api/v1",
    // URL_API_BASE: "http://localhost:4002/api/v1",
    URL_PAYMENT_PAYPAL: "https://secure.transroute.com.mx",
    URL_API_BASE: "https://api-laislatour.transroute.com.mx/api/v1",

    //URL_API_BASE: "https://api-laislatours.vercel.app/api/v1",
    // URL_API_BASE: "https://api-laislatours-be23oypzf-jensondesign.vercel.app/api/v1",
    ENVIRONMENT: "DEVELOPMENT",
};

export default configs;
