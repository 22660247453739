import React, { Fragment, useState } from "react";
import Swal from "sweetalert2";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import LoadingOverlay from "react-loading-overlay";

import configs from "../../config/config";

import { stripePayment } from "../../api_controllers/payment_controller";
import { Button } from "reactstrap";

let key =
    configs.ENVIRONMENT === "DEVELOPMENT"
        ? "pk_test_51KN04vD1703QVxnA4OKiuZMctbnUlZT6oEVoT4weiRyd62gYzZu7kEdqL8AiwqIkkuuHSasTKD2icdMIxS4OEn3N00Nw01uwWG"
        : "pk_live_51HgMPZKtM7BBO6PLDF9lNuHc1s1rWippagOkgSDQp6pnb9hTyAU0uVlPcVEJCVb9YDGAODhq4svpItY3S3sA0GsB000ZOhgwL2";

const stripePromise = loadStripe(key);

export default function PaymentStripe({ stopLoader, startLoader, lang, toggleCloseModal, totalPayment, handlePayNow }) {
    //{form, setLoaderController, handlePayNow, firstFormValidator, lang, totalPayment}
    //  console.log("--form",form)
    const [loaderController, setLoaderController] = useState(false);
    var option = 0;

    const CheckoutForm = () => {
        // Make sure to call `loadStripe` outside of a component’s render to avoid
        // recreating the `Stripe` object on every render.
        const stripe = useStripe();
        const elements = useElements();

        const pay = async () => {
            option++;

            if (option === 1) {
                try {
                    const cardElement = elements.getElement(CardElement);
                    const { error, paymentMethod } = await stripe.createPaymentMethod({
                        type: "card",
                        card: cardElement,
                    });

                    if (error) {
                        console.log("[error]", error);
                        Swal.fire("Oops!", "Something went wrong, charge is declined... try again later.", "error");
                        option = 0;
                    } else {
                        const { id } = paymentMethod;
                        let data = {
                            id,
                            amount: parseInt(localStorage.getItem("details")) * 100, // centavos
                            description: localStorage.getItem("desc"),
                        };

                        stripePayment(data)
                            .then((response) => {
                                //console.log('Payment response', response.data);
                                let res = response.data;

                                if (res.valid === true) {
                                    handlePayNow(res.payment.id);
                                    option = 0;
                                } else {
                                    option = 0;
                                }
                            })
                            .catch((error) => {
                                console.log("Error", error);
                                Swal.fire("Oops!", "Something went wrong, charge is declined... try again later.", "error");
                                option = 0;
                            });
                    }
                } catch (error) {
                    Swal.fire("Oops!", "Something went wrong, charge is declined... try again later.", "error");
                    option = 0;
                }
            }
        };

        return (
            <Fragment>
                <LoadingOverlay className="loading" active={loaderController} spinner text="Processing data...">
                    <CardElement />
                    <div className="shield">
                        {lang === "eng" ? "Your payments are made securely with 256-bit encryption" : "Tus pagos son realizados de manera segura con encriptación de 256-bits"}
                    </div>
                    <div className="mt-3 buttons-payments">
                        <Button color="warning" onClick={toggleCloseModal}>
                            {lang === "eng" ? "MODIFY RESERVE" : "MODIFICAR RESERVACIÓN"}
                        </Button>
                        <Button color="primary" className="button-pay" onClick={pay}>
                            {lang === "eng" ? "PAY NOW" : "PAGAR AHORA"}
                        </Button>
                    </div>
                </LoadingOverlay>
            </Fragment>
        );
    };

    return (
        <div>
            <Elements stripe={stripePromise}>
                <CheckoutForm />
            </Elements>
        </div>
    );
}
